<template>
  <div class="bg-white h-view overflow-auto w-full flex flex-col">
    <div class="grow flex flex-row items-center justify-center">
      <h2 v-if="noEvents" class="max-w-(--breakpoint-xl) p-8 flex flex-col justify-center items-center text-2xl text-center">
        Programmet har ikke blitt sluppet for UL enda, men sjekk tilbake senere!
      </h2>
      <div v-else class="w-full max-w-(--breakpoint-xl) flex flex-col self-center space-y-4 pt-4 pb-12 mx-auto">
        <Toggle v-model="showUlPluss" label="Vis UL PLUSS" class="ml-2" />

        <div class="w-full flex flex-col justify-center items-center">
          <div
            class="w-full flex flex-row justify-center shadow-sm bg-ul-orange-150 duration-200"
            :class="activePostIndex === 0 ? '' : ''"
          >
            <div
              v-for="(day, idx) in days"
              :key="day.name"
              class="w-1/5 h-16 sm:h-24 cursor-pointer font-semibold flex justify-center items-center duration-200 relative first: last: hover:bg-opacity-75 text-lg tracking-wide"
              :class="[
                activeDayIndex == idx
                  ? 'bg-ul-orange-300 text-white shadow-lg  z-1 '
                  : 'text-ul-orange-400 hover:bg-ul-orange-100',
                activePostIndex === 0 ? 'first: last:' : '',
              ]"
              @click="selectDay(idx)"
            >
              <span class="hidden sm:inline-block">{{ day.name }}</span>
              <span class="sm:hidden">{{ day.name.substring(0, 3) }}</span>
            </div>
          </div>
          <div class="w-full flex flex-col">
            <div
              v-for="(post, idx) in day"
              :key="post.title + post.timestamp + idx"
              class="w-full flex flex-col duration-200 shadow-sm hover:cursor-pointer py-2 space-y-4"
              :class="[
                idx + 1 === activePostIndex ? '' : '',
                idx === activePostIndex
                  ? 'my-4  even:bg-ul-orange-150 odd:bg-ul-orange-200'
                  : 'even:bg-ul-orange-50 odd:bg-ul-orange-100',
                idx - 1 === activePostIndex ? '' : '',
              ]"
              @click="togglePost(idx)"
            >
              <div class="flex flex-row">
                <div class="w-32 flex grow justify-center items-center">
                  <div class="relative">
                    <img
                      :src="post.image || getProgramPostImage(post.type)"
                      alt=""
                      class="h-16 w-16 object-cover duration-300"
                    />
                    <div class="absolute top-10 left-6 -rotate-6" v-if="post.ulPluss">
                      <p class="relative flex items-center justify-center bg-ul-orange-400 text-white font-bold px-2">
                        UL+
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full text-ul-orange-300 flex flex-col justify-center pr-2">
                  <p class="text-ul-orange-350 max-w-full uppercase tracking-wider text-sm">
                    {{ getTimestampString(post.timestamp) }}
                  </p>
                  <p class="font-bold max-w-full text-ul-orange-400">{{ post.title }}</p>
                  <p class="text-ul-orange-600 max-w-full">{{ post.location }}</p>
                </div>
              </div>
              <div
                class="w-full flex px-4 items-center text-left"
                :class="idx === activePostIndex && post.description ? '' : 'hidden'"
              >
                <p class="v-html text-black" v-html="post.description" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PageFooter class="shrink-0" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { computed } from 'vue'
import { onMounted } from 'vue'
import { watch } from 'vue'

import activites from '../assets/program/Aktiviteter.png'
import other from '../assets/program/Annet.png'
import sermon from '../assets/program/Møte.png'
import seminar from '../assets/program/Seminar.png'
import entertainment from '../assets/program/Underholdning.png'
import PageFooter from '../components/PageFooter.vue'
import Toggle from '../components/Toggle.vue'
import { useEventStore } from '../stores/events'
import { Event } from '../types'

const days = [{ name: 'Tirsdag' }, { name: 'Onsdag' }, { name: 'Torsdag' }, { name: 'Fredag' }, { name: 'Lørdag' }]

const showUlPluss = ref(false)
const activeDayIndex = ref(0)
const activePostIndex = ref<number | null>(null)

const eventStore = useEventStore()
const noEvents = computed(() => eventStore.events.length === 0)
const week = computed(() => {
  const week: Event[][] = [[], [], [], [], []]
  const programPosts = eventStore.events.toSorted((a, b) => (a.timestamp >= b.timestamp ? 1 : -1))
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  for (const programPost of programPosts) {
    const day = days[new Date(programPost.timestamp).getDay()]
    switch (day) {
      case 'tuesday':
        week[0].push(programPost)
        break
      case 'wednesday':
        week[1].push(programPost)
        break
      case 'thursday':
        week[2].push(programPost)
        break
      case 'friday':
        week[3].push(programPost)
        break
      case 'saturday':
        week[4].push(programPost)
        break
    }
  }
  return week
})

const day = computed(() => week.value[activeDayIndex.value].filter((event) => showUlPluss.value || !event.ulPluss))

function selectDay(dayIndex: number) {
  activeDayIndex.value = dayIndex
  activePostIndex.value = null
}
function togglePost(postIndex: number) {
  activePostIndex.value = activePostIndex.value === postIndex ? null : postIndex
}

function getDayString(weekdayIndex: number) {
  return ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'][weekdayIndex]
}
function getTimestampString(timestamp: string) {
  const date = new Date(timestamp)
  return getDayString(date.getDay()) + ' ' + date.toLocaleTimeString().substring(0, 5)
}

function getProgramPostImage(type: string) {
  switch (type) {
    case 'Møte':
      return sermon
    case 'Seminar':
      return seminar
    case 'Aktivitet':
      return activites
    case 'Underholdning':
      return entertainment
    case 'Annet':
    default:
      return other
  }
}

onMounted(() => {
  const hashValue = window.location.hash.replace('#', '')
  const hasLocalStorageItem = localStorage.getItem('program-show-ul-pluss') !== null
  if (hashValue === 'pluss' || hasLocalStorageItem) {
    showUlPluss.value = true
  }
  window.location.hash = ''
})

watch(showUlPluss, (newValue) => {
  if (newValue) {
    localStorage.setItem('program-show-ul-pluss', 'true')
  } else {
    localStorage.removeItem('program-show-ul-pluss')
  }
})
</script>

<style lang="scss"></style>
