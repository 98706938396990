<template>
  <div class="bg-white min-h-view overflow-auto flex flex-col">
    <div v-if="noTickets" class="md:max-w-(--breakpoint-xl) md:mx-auto grow p-8 flex flex-col justify-center">
      <h2 class="text-2xl text-center">Billettene har ikke blitt sluppet for UL enda, men sjekk tilbake senere!</h2>
    </div>
    <div v-else class="grow flex flex-row flex-wrap justify-center items-center w-full overflow-auto py-6 my-5">
      <Ticket v-for="(ticket, idx) in tickets" :key="idx" :ticket="ticket" />
    </div>

    <PageFooter />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import PageFooter from '../components/PageFooter.vue'
import Ticket from '../components/Ticket.vue'
import { useTicketStore } from '../stores/ticket'

const ticketStore = useTicketStore()
const tickets = computed(() => ticketStore.tickets)
const noTickets = computed(() => tickets.value.length === 0)
</script>

<style lang="scss"></style>
