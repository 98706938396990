<template>
  <div class="pluss h-view overflow-auto">
    <!-- Desktop version -->
    <div class="hidden lg:block h-view bg-ul-gray-300 text-ul-orange-50 font-display font-medium relative">
      <div class="flex flex-row h-5/6">
        <div class="relative w-1/2 text-black">
          <img
            :srcSet="plussTrappImageSourceSet"
            :sizes="plussTrappSizes"
            :src="plussTrappImage2k"
            role="presentation"
            class="w-full h-full object-cover object-top"
            alt="UL Pluss, tema: Håp. Illustrasjon av en person som går opp en trapp til en stor port"
          />
        </div>
        <div class="flex flex-col w-1/2">
          <div class="flex flex-row h-7/12">
            <div class="flex flex-col w-3/12 h-full">
              <img :src="konsertImage" role="presentation" class="w-full h-3/5 object-cover" />
              <img :src="plussImage" role="presentation" class="w-full h-2/5 object-cover" />
            </div>
            <div class="flex flex-col w-4/12 h-full">
              <img :src="climbImage" role="presentation" class="w-full h-1/2 object-cover" />
              <img :src="kaffeImage" role="presentation" class="w-full h-1/2 object-cover" />
            </div>
            <div class="flex flex-col w-5/12 h-full">
              <img :src="lovsangImage" role="presentation" class="w-full h-3/5 object-cover" />
              <!-- <div class="w-full h-1/4 flex justify-center items-center text-center text-4xl xl:text-5xl 2xl:text-6xl">
                8.-12. juli
              </div> -->
              <img :src="talkshowImage" role="presentation" class="w-full h-2/5 object-cover" />
            </div>
          </div>
          <div
            class="flex justify-center items-center text-center w-full h-1/6 bg-ul-gray-400 text-3xl xl:text-4xl 2xl:text-5xl"
          >
            Stavanger
          </div>
          <div class="flex flex-row h-1/4 relative">
            <img :src="praiseImage" role="presentation" class="w-full h-full object-cover" />
            <div
              class="flex flex-row h-1/3 w-1/2 absolute top-0 left-0 bg-ul-gray-300 justify-center items-center text-cente text-2xl xl:text-3xl 2xl:text-4xl"
            >
              Festival som forandrer
            </div>
            <div class="flex flex-row h-1/3 w-7/12 absolute bottom-0 right-0">
              <div
                class="bg-ul-gray-100 text-ul-gray-400 w-1/4 flex justify-center items-center text-center text-3xl xl:text-4xl 2xl:text-5xl"
              >
                22+
              </div>
              <div
                class="bg-ul-gray-300 w-3/4 flex justify-center items-center text-center text-2xl xl:text-3xl 2xl:text-4xl"
              >
                Festival i festivalen
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="h-1/6 flex flex-row">
        <div class="w-3/5 flex flex-row">
          <GridImageButton
            v-for="linkBox in linkBoxes"
            :key="linkBox.text"
            :image="linkBox.image"
            :text="linkBox.text"
            :link="linkBox.link"
            color="gray"
            class="w-1/4 text-lg xl:text-2xl 2xl:text-4xl"
          />
        </div>
        <div class="w-2/5 flex flex-row">
          <img :src="paintImage" role="presentation" class="w-1/2 h-full object-cover" />
          <img :src="seminarImage" role="presentation" class="w-1/2 h-full object-cover" />
        </div>
      </div>
    </div>

    <!-- Mobile version -->
    <div class="lg:hidden h-view bg-ul-gray-300 text-ul-orange-50 font-display font-medium relative">
      <div class="flex flex-col h-full">
        <div class="relative w-full h-1/3">
          <img
            :srcSet="plussTrappImageSourceSet"
            :sizes="plussTrappSizes"
            :src="plussTrappImage2k"
            role="presentation"
            class="w-full h-full object-cover object-top"
            alt="UL Pluss, tema: Håp. Illustrasjon av en person som går opp en trapp til en stor port"
          />
        </div>

        <div class="flex flex-row h-1/8">
          <img :src="plussImage" role="presentation" class="w-2/5 h-full object-cover" />
          <div class="flex justify-center items-center w-3/5 text-center text-4xl xs:text-5xl sm:text-6xl md:text-7xl">
            8.-12. juli
          </div>
        </div>

        <div class="flex flex-row h-1/8">
          <img :src="lovsangImage" role="presentation" class="w-1/5 h-full object-cover" />
          <img :src="talkshowImage" role="presentation" class="w-2/5 h-full object-cover" />
          <img :src="seminarImage" role="presentation" class="w-2/5 h-full object-cover" />
        </div>

        <div class="flex flex-row h-1/12">
          <img :src="programImage" role="presentation" class="w-5/12 h-full object-cover" />
          <div
            class="flex justify-center items-center w-1/4 bg-ul-gray-100 text-ul-gray-400 text-center text-4xl xs:text-5xl sm:text-6xl md:text-7xl"
          >
            22+
          </div>
          <img :src="campingImage" role="presentation" class="w-4/12 h-full object-cover" />
        </div>

        <div
          class="h-1/8 bg-ul-gray-400 text-center flex justify-center items-center text-2xl xs:text-3xl sm:text-4xl md:text-5xl"
        >
          Siddishallen & Vikinghallen, Stavanger
        </div>

        <div class="flex flex-row h-1/8">
          <img :src="climbImage" role="presentation" class="w-2/5 h-full object-cover" />
          <div
            class="flex justify-center items-center w-3/5 bg-ul-gray-200 text-ul-gray-400 text-center text-2xl xs:text-3xl sm:text-4xl md:text-5xl"
          >
            Festival som forandrer
          </div>
        </div>

        <div class="flex flex-row grow min-h-0">
          <img :src="rockClimbingImage" role="presentation" class="w-1/6 h-full object-cover" />
          <img :src="ticketsImage" role="presentation" class="w-3/6 h-full object-cover" />
          <img :src="paintImage" role="presentation" class="w-2/6 h-full object-cover" />
        </div>
      </div>
    </div>

    <PageFooter />
  </div>
</template>

<script setup lang="ts">
import campingImage from '../assets/images/camping.jpg'
import climbImage from '../assets/images/climb.jpg'
import kaffeImage from '../assets/images/kaffe.jpg'
import konsertImage from '../assets/images/konsert.jpg'
import lovsangImage from '../assets/images/lovsang.jpg'
import paintImage from '../assets/images/paint.jpg'
import plussTrappImage1k from '../assets/images/pluss-trapp-1k.png'
import plussTrappImage2k from '../assets/images/pluss-trapp-2k.png'
import plussTrappImage500 from '../assets/images/pluss-trapp-500.png'
import plussImage from '../assets/images/pluss.jpg'
import praiseImage from '../assets/images/praise3.jpg'
import programImage from '../assets/images/program.jpg'
import rockClimbingImage from '../assets/images/rock-climbing.png'
import seminarImage from '../assets/images/seminar.jpg'
import talkshowImage from '../assets/images/talkshow.jpg'
import ticketsImage from '../assets/images/tickets.jpg'
import GridImageButton from '../components/GridImageButton.vue'
import PageFooter from '../components/PageFooter.vue'

const plussTrappImageSourceSet = `${plussTrappImage500} 500w, ${plussTrappImage1k} 1000w, ${plussTrappImage2k} 2000w`
const plussTrappSizes = '(max-width: 500px) 500px, (max-width: 1000px) 1000px, 2000px'

const linkBoxes = [
  { text: 'UL+ Program', link: '/program#pluss', image: campingImage },
  { text: 'Billetter', link: '/billetter', image: rockClimbingImage },
  { text: 'UL+ Aktiviteter', link: '/info#aktiviteter-ulpluss', image: programImage },
  { text: 'UL+ Overnatting', link: '/info#overnatting', image: ticketsImage },
]
</script>

<style lang="scss"></style>
